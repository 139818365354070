import { defineNuxtRouteMiddleware } from 'nuxt/app';
import useAppData from '@/composables/useAppData';
import { useStoreAppDataIndex } from '@/stores/app/data';

export default defineNuxtRouteMiddleware((to, from) => {
    const storeData = useStoreAppDataIndex();

    // console.log('middleware:data:loaded', storeData.loaded);
    if (!storeData.loaded) {
        useAppData().setData((data: any) => {
            console.log('data', data.config);
            storeData.setData(data);
        });
    }
});
