import fs from 'fs';
import path from 'path';
import axios from '@/plugins/common/axios';
import { useStoreApiDataIndex } from '@/stores/api/data';

const VITE_APP_SUBDOMAIN = import.meta.env.VITE_APP_SUBDOMAIN;

export default function useAppData() {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const isProduction = process.env.NODE_ENV === 'production';
    const subdomain = VITE_APP_SUBDOMAIN || 'default';

    const setDataFromLocalStorage = async (callback: any) => {
        const store = useStoreApiDataIndex();
        if (store.isEmpty()) {
            await store.fetch((data) => {
                callback(data);
            });
        } else {
            callback(store.data);
        }
    };
    const setDataFromJson = async (callback: any) => {
        let data = null;
        if (isProduction) {
            data = await JSON.parse(fs.readFileSync(path.resolve(process.cwd(), `public/data.${subdomain}.json`), 'utf-8'));
        } else {
            data = await JSON.parse(fs.readFileSync(`src/public/data.${subdomain}.json`, 'utf-8'));
        }
        callback(data);
    };
    const setDataFromUrl = async (callback: any) => {
        let dir = `src/public/data.${subdomain}.json`;
        dir = dir.replace('src/public', '');
        await axios.getLocal(dir, {}, (data: any) => {
            callback(data);
        });
        // return data.value;
    };

    const setData = async (callback: any) => {
        if (!process.client && isDevelopment) {
            await setDataFromJson(callback);
        }
        if (!process.client && isProduction) {
            await setDataFromJson(callback);
        }
        if (process.client && isProduction) {
            // await setDataFromLocalStorage(callback);
        }
    };

    return {
        setData,
    };
}
